<template>
  <validation-observer
    ref="refFormObserver"
  >
    <!-- #default="{ pristine }" -->
    <b-modal
      id="modal-reservation-modify-insurance-edit-cover-note"
      :title="$t('reservation[\'Edit cover note information\']')"
      title-class="text-airline font-medium-4 font-weight-bolder mb-0"
      body-class="px-75"
      footer-class="px-50"
      centered
      no-close-on-backdrop
      size="lg"
      @show="handleShowModal"
      @hide="handleHideModal"
    >
      <template #modal-footer="{ close }">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary px-1 px-sm-2"
          class="center rounded-pill"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="info"
          class="px-1 px-sm-2"
          :disabled="!isConfirm"
          pill
          @click="handleAddInsuranceServices"
        >
          <span class="fw-700"> {{ $t('reservation[\'confirm\']') }} </span>
        </b-button>
      </template>

      <div v-if="coverNoteToEdit">
        <b-card
          header-bg-variant="light-warning"
          header-class="py-25 px-25 px-md-1"
          class="border"
          body-class="p-75 px-md-2"
        >
          <b-row class="mb-25">
            <b-col
              cols="5"
              lg="4"
            >{{ $t('reservation[\'serviceName\']') }}:</b-col>
            <b-col
              cols="7"
              class="fw-700"
            >
              {{ coverNoteToEdit.planName || '' }}
            </b-col>
          </b-row>

          <b-row class="mb-50">
            <b-col
              cols="5"
              lg="4"
            >
              {{ $t('reservation[\'status\']') }}:
            </b-col>
            <b-col
              cols="7"
            >
              <b-badge
                :variant="resolveVariantInsuranceServices(coverNoteToEdit.status)"
              >
                {{ resolveInsuranceSpecialServicesName(coverNoteToEdit.status) }}
              </b-badge>
            </b-col>
          </b-row>

          <b-row class="mb-50">
            <b-col
              cols="5"
              lg="4"
            >
              {{ $t('reservation[\'bookingCode\']') }}:
            </b-col>
            <b-col
              cols="7"
              class="fw-700 text-airline"
            >
              {{ coverNoteToEdit.bookingCode }}
            </b-col>
          </b-row>

          <b-row class="mb-50">
            <b-col
              cols="5"
              lg="4"
            >{{ $t('reservation[\'itinerary\']') }}:</b-col>
            <b-col
              cols="7"
              class="text-airline fw-700"
            >
              {{ resolveInsuranceItinerariesName(coverNoteToEdit.flightJourney, 'journeys') }}
            </b-col>
          </b-row>
          <b-row class="mb-50">
            <b-col
              cols="5"
              lg="4"
            >{{ $t('reservation[\'Start time\']') }}:</b-col>
            <b-col
              cols="7"
              class="text-airline fw-700"
            >
              {{ convertISODateTime(coverNoteToEdit.flightJourney[0].boardingTime, 'UTC').dateTime }}
            </b-col>
          </b-row>
          <b-row class="mb-50">
            <b-col
              cols="5"
              lg="4"
            >{{ $t('reservation[\'Customer name used\']') }}:</b-col>
            <b-col
              cols="7"
              class="text-airline fw-700"
            >
              {{ coverNoteToEdit.passengerName }}
            </b-col>
          </b-row>
        </b-card>

        <b-card
          class="border"
          body-class="p-75"
        >
          <b-row class="mx-0">
            <!-- ANCHOR Phone -->
            <b-col
              cols="12"
              lg="6"
            >
              <validation-provider
                #default="validationContext"
                name="Phone"
                rules="required|phoneNumber"
              >
                <b-form-group label-for="phone">
                  <template #label>
                    {{ $t('reservation[\'Contact phone number\']') }} <span class="text-danger">(*)</span>
                  </template>
                  <b-form-input
                    id="phone"
                    v-model="coverNoteToEdit.phoneNumber"
                    v-remove-non-numeric-chars.allNumber
                    :placeholder="$t('reservation[\'placeholderInput\']')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    lazy-formatter
                    :formatter="trimInput"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- ANCHOR Email -->
            <b-col
              cols="12"
              lg="6"
            >
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required|email"
              >
                <b-form-group label-for="email">
                  <template #label>
                    {{ $t('reservation[\'Contact email\']') }} <span class="text-danger">(*)</span>
                  </template>
                  <b-form-input
                    id="email"
                    v-model="coverNoteToEdit.email"
                    :placeholder="$t('reservation[\'placeholderInput\']')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    lazy-formatter
                    :formatter="trimInput"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          header-bg-variant="light-warning"
          header-class="py-25 px-25 px-md-1"
          class="border"
          body-class="p-75"
        >
          <b-row class="mx-0">
            <!-- ANCHOR  Tên khách hàng -->
            <!-- <b-col
              cols="12"
              lg="6"
              xl="4"
            >
              <b-form-group>
                <template #label>
                  Tên khách hàng sử dụng
                </template>
                <b-form-input
                  :value="coverNoteToEdit.passengerName"
                  disabled
                />
              </b-form-group>
            </b-col> -->
            <!-- ANCHOR Ngày sinh -->
            <b-col
              cols="12"
              lg="6"
            >
              <validation-provider
                #default="validationContext"
                name="Ngày sinh"
                rules="required"
                vid="passenger-birthday"
              >
                <b-form-group>
                  <template #label>
                    {{ $t('reservation[\'DoB\']') }} <span class="text-danger">(*)</span>
                  </template>
                  <flat-pickr
                    v-model="coverNoteToEdit.birthDay"
                    class="d-none"
                  />
                  <div
                    class="form-control p-0"
                    :class="validationContext.errors[0] ? 'is-invalid' : ''"
                  >
                    <flat-pickr
                      id="passenger-birthday"
                      v-model="coverNoteToEdit.birthDay"
                      :name="$t('flight.dob')"
                      class="form-control px-50 py-0"
                      :config="{
                        ...configFlatPickr,
                        altInputClass: 'form-control input px-50 py-0 border-transparent',
                      }"
                      :placeholder="$t('flight.dob')"
                    />
                  </div>
                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    target="passenger-birthday"
                    triggers="hover focus"
                    placement="top"
                    boundary="window"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- ANCHOR  Hộ chiếu -->
            <b-col
              cols="12"
              lg="6"
            >
              <validation-provider
                #default="validationContext"
                name="Hộ chiếu"
                rules="required|min:8|max:20"
                vid="passenger-passport"
              >
                <b-form-group>
                  <template #label>
                    {{ $t('reservation[\'Passport\']') }} <span class="text-danger">(*)</span>
                  </template>
                  <b-form-input
                    id="passenger-passport"
                    v-model="coverNoteToEdit.passport"
                    :state="getValidationState(validationContext) === false ? false : null"
                    placeholder="Vui lòng nhập"
                    lazy-formatter
                    :formatter="upperCaseFormatter"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <IAmConfirm
        :is-confirm.sync="isConfirm"
      />

    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal,
  BButton,
  BFormGroup,
  BCard,
  BFormInput,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BTooltip,
  BBadge,
} from 'bootstrap-vue'
import {
  ref, toRefs, computed,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { cloneDeep, isEmpty } from 'lodash-es'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import { resolveInsuranceSpecialServicesName, resolveVariantInsuranceServices } from '@/constants/selectOptions'
import VueI18n from '@/libs/i18n'
import { convertISODateTime } from '@/@core/utils/filter'

import {
  upperCaseFormatter,
  trimInput,
} from '@core/comp-functions/forms/formatter-input'
import formValidation from '@core/comp-functions/forms/form-validation'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BTooltip,
    flatPickr,
    BBadge,

    ValidationObserver,
    ValidationProvider,

    // IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmConfirm: () => import('@/components/IAmConfirm.vue'),
  },
  directives: {
    Ripple,
  },
  props: {
    dataCoverNote: {
      type: [Object, null],
      default: () => null,
    },
  },
  setup(props, { root }) {
    const { toastError } = useToast()
    const { getBookingData, editInsuranceCoverNote, resolveInsuranceItinerariesName } = useReservationHandle()
    const { dataCoverNote } = toRefs(props)
    const isConfirm = ref(false)
    const coverNoteToEdit = ref(cloneDeep(dataCoverNote.value))

    const resetCoverNoteToEdit = () => {
      coverNoteToEdit.value = cloneDeep(dataCoverNote.value)
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetCoverNoteToEdit)

    function handleAddInsuranceServices() {
      const data = coverNoteToEdit.value

      this.$refs.refFormObserver.validate()
        .then(success => {
          if (!success) {
            toastError({ title: 'messagesList.error', content: 'reservation[\'Please check again and enter complete information\']' })
          } else {
            this.$bvModal.show('modal-api-loading')

            const payload = {
              bookingCode: data.bookingCode,
              bookingSource: getBookingData.value?.source,

              insuranceId: data.id,
              birthDay: data.birthDay,
              passport: data.passport,
              phoneNumber: data.phoneNumber,
              emailAddress: data.email,
              // promoCode: '',
            }

            editInsuranceCoverNote(payload)
              .then(res => {
                this.$bvModal.hide('modal-reservation-modify-insurance-edit-cover-note')
                this.$bvModal.hide('modal-modify-special-services-insurance-info')

                // Array.from(window.document.querySelectorAll('.modal.show'))
                //   .map(el => el.id)
                //   .forEach(id => {
                //     root.$root.$emit('bv::hide::modal', id)
                //   })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function handleShowModal() {
      resetCoverNoteToEdit()
      isConfirm.value = false
    }

    function handleHideModal() {
      //
    }

    const locale = computed(() => VueI18n.locale).value

    const today = new Date()
    const maxDate = new Date(today)
    maxDate.setDate(maxDate.getDate() - 1)
    const minDate = new Date(today)
    minDate.setFullYear(minDate.getFullYear() - 75)

    const configFlatPickr = ref({
      dateFormat: 'Y-m-d',
      minDate: minDate.toLocaleDateString('en-CA'),
      maxDate: maxDate.toLocaleDateString('en-CA'),
      locale: locale === 'vi' ? Vietnamese : null,
      allowInput: true,
      altInput: true,
      altFormat: 'd-m-Y',
      shorthandCurrentMonth: true,
      disableMobile: true,
    })

    return {
      convertISODateTime,
      isConfirm,
      isEmpty,
      trimInput,
      upperCaseFormatter,
      configFlatPickr,
      handleShowModal,
      handleHideModal,
      refFormObserver,
      getValidationState,
      resetForm,
      coverNoteToEdit,
      handleAddInsuranceServices,
      resolveInsuranceSpecialServicesName,
      resolveVariantInsuranceServices,
      resolveInsuranceItinerariesName,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
#radio-group-bookingType::v-deep {
  .custom-control.custom-radio {
    margin-bottom: 12px;

    .custom-control-label {
      margin-top: 0px;
      font-size: 16px;
    }
  }
}
</style>
